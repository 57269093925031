import React from 'react';
import Layout from './src/components/Layout/Layout';

import 'normalize.css';
import './src/styles/main.scss';
import heroVersion from './src/utils/version';

export function onClientEntry() {
  const heroVer = heroVersion();

  document.body.classList.add(`is-version-${heroVer.version}`);
  document.body.classList.add(`is-version-reason-${heroVer.reason}`);

  // @HACK: Force reflow of dnd-zone which fixes Chrome rendering issues
  let ticks = 0;
  const forceDndHeroReflow = () => {
    const dndZoneEl = document.getElementById('dnd-zone');

    if (dndZoneEl) {
      dndZoneEl.style.width = '';
      dndZoneEl.style.width = '100%';
    }

    ticks += 1;

    if (ticks < 100) {
      setTimeout(forceDndHeroReflow, 100);
    }
  };

  setTimeout(forceDndHeroReflow, 100);
}

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function shouldUpdateScroll({
  routerProps: { location },
  prevRouterProps,
}) {
  if (location.pathname.match(/category|resources|portfolio/g)) {
    if (prevRouterProps) {
      if (
        prevRouterProps.location.pathname.match(/category|resources|portfolio/g)
      ) {
        if (
          location.pathname.match(/resources/g) ===
          prevRouterProps.location.pathname.match(/resources/g)
        ) {
          return false;
        }
        if (
          location.pathname.match(/portfolio/g) ===
          prevRouterProps.location.pathname.match(/portfolio/g)
        ) {
          return false;
        }
      }
    }
  }
  return window.scrollTo([0, 0]);
}
