export default function heroVersion() {
  // URL param 'hero' should be set to 'static' or 'dnd' (drag and drop) for overriding header
  const urlParam = new URLSearchParams(window.location.search);
  const versionParam = urlParam.get('hero');

  // header saved in local storage
  const cachedHeroVersion = localStorage.getItem('version');

  // window dimensions
  // minimum window width for drag and drop header
  const windowWidth = window.innerWidth;
  // screen-md breakpoint
  const MIN_PX_WIDTH_FOR_VERSION_ASSIGNMENT = 768;

  // if URL header param exists, it takes priority over all else to set the header
  if (versionParam) {
    return {
      version: versionParam,
      reason: 'url',
    };
  }

  if (windowWidth < MIN_PX_WIDTH_FOR_VERSION_ASSIGNMENT) {
    // set hero state to static
    return {
      version: 'static-version',
      reason: 'dimensions',
    };
  }

  if (cachedHeroVersion) {
    // use stored header
    if (cachedHeroVersion === 'static-hero') {
      // use static header
      return {
        version: 'static-version',
        reason: 'cached',
      };
    }
    if (cachedHeroVersion === 'drag-and-drop-hero') {
      // use drag and drop header
      return {
        version: 'drag-and-drop-version',
        reason: 'cached',
      };
    }
  }

  // use random header based on current date seconds
  const currentSeconds = new Date().getSeconds();

  // checking if second is an odd or even number
  if (currentSeconds % 2 === 0) {
    // even - use static header
    localStorage.setItem('version', 'static-hero');
    return {
      version: 'static-version',
      reason: 'random',
    };
  }
  // odd - use drag and drop header
  localStorage.setItem('version', 'drag-and-drop-hero');
  return {
    version: 'drag-and-drop-version',
    reason: 'random',
  };
};
